import  React, { useState } from "react";
import Logo from "../images/logo.png";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "gatsby";

function Header() {
  const [ mobileMenu, setMobileMenu ] = useState(null);

    return (
      <>
        <header className="absolute left-0 text-indigo-100 text-opacity-75 body-font w-full z-20 bg-transparent" style={{top: 90}}>
          <div className="container px-6 mx-auto flex flex-wrap flex-row items-center justify-center">
            {/* Logo */}
            <Link to="/" className="flex font-medium items-center"><img src={Logo} alt={"logo"} className="w-52" /></Link>

            {/* Burger Menu */}
            {/* <FiMenu className="lg:hidden cursor-pointer text-white text-4xl" onClick={() => setMobileMenu(!mobileMenu)} /> */}

            {/* Desktop Menu */}
            {/* <nav className="lg:ml-auto my-4 lg:my-0 flex-wrap items-center justify-center">
              <Link to="/templates" className="pr-5 hover:text-white py-2 md:py-6 flex items-center">Templates</Link>
              <Link to="/templates" className="pr-5 hover:text-white py-2 md:py-6 flex items-center">Tutorial</Link>
              <Link to="/pricing" className="pr-5 hover:text-white py-2 md:py-6 flex items-center">Pricing</Link>
            </nav> */}

            {/* Login/Button */}
            {/* <div>
              <button className="inline-flex items-center ml-4 bg-indigo-700 text-green-100 transition duration-150 border border-indigo-600 py-2 px-4 focus:outline-none hover:bg-indigo-600 rounded text-base">Create Video Mockup</button>
            </div> */}
          </div>

        </header>

        {/* Mobile Menu */}
        {mobileMenu && (
          <div className={"animate__animated animate__faster w-64 bg-gray-800 shadow-2xl fixed left-0 top-0 h-screen z-50 px-6 py-4 text-white " + (mobileMenu === true ? "animate__slideInLeft" : "animate__slideOutLeft")}>
            <div className="cursor-pointer flex items-center justify-center rounded text-xl h-8 w-8 bg-white bg-gray-700 hover:bg-gray-600 transition-all duration-100 mb-6" onClick={() => setMobileMenu(!mobileMenu)}><FiX /></div>
            <Link to="/create" className="pr-5 hover:text-gray-400 py-1 flex items-center">Create</Link>
            <Link to="/tour" className="pr-5 hover:text-gray-400 py-1 flex items-center">Tour</Link>
            <Link to="/templates" className="pr-5 hover:text-gray-400 py-1 flex items-center">Templates</Link>
            <Link to="/pricing" className="pr-5 hover:text-gray-400 py-1 flex items-center">Pricing</Link>
            <div className="border-t border-gray-700 pt-5 mt-5">
              <button className="inline-flex items-center text-gray-400 border border-gray-600 py-1 px-3 focus:outline-none hover:border-white rounded text-xs">Login</button>
              <button className="inline-flex items-center ml-4 bg-green-500 text-green-100 border border-green-400 py-2 px-4 focus:outline-none hover:bg-green-600 rounded text-base">Create Video</button>
            </div>
          </div>
        )}
      </>
    )
}

export default Header;
