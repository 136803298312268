import PropTypes from "prop-types";
import React from "react";
import { FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import logo from "../images/logo.png";
import { Link } from "gatsby";
import { useLocation } from '@reach/router'

import Header from "./header";

const usefulPages = [
  { name: 'Home', link: '/' },
  { name: 'Device Stock Video Maker', link: '/device-stock-video-mockup' }
];

const legalPages = [
  { name: 'License', link: '/license' },
  { name: 'Device Stock Video Maker', link: '/device-stock-video-mockup' }

]

function Layout({ children }) {
  const d = new Date();
  const currentYear = d.getFullYear();
  const { search } = useLocation();
  const name = search == "?ref=producthunt" ? ' product hunter' : ''
  return (
    <>
     <div className="bg-indigo-800 text-center text-white text-opacity-85 p-2.5">Hey{name}! We've just launched 🚀 Grab 10% off for a limited time with code hunter2021</div>
    <div className="flex flex-col min-h-screen overflow-x-hidden w-full">
      <Header />

      <main className="w-full h-full mx-auto">
        {children}
      </main>

      <footer className="bg-black text-gray-400 pt-3">
        <div className="container px-6 py-5 mx-auto flex sm:flex-row flex-col flex-wrap">          
          <div className="w-full md:w-1/4 mb-4">
            <a className="">
              <img src={logo} alt="logo" className="h-6" />
            </a>
          </div>
          <div className="w-full md:w-1/4 pr-4 mb-3">
            Useful Pages
            <ul className="text-sm text-gray-600">
              {usefulPages.map((el, i) => (
                  <li key={'useful-'+ i}><Link className="hover:text-gray-500" to={el.link}>{el.name}</Link></li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/4 pr-4 mb-3">
            Legal
            <ul className="text-sm text-gray-600">
              <li><Link className="hover:text-gray-500" to={'/license'}>License</Link></li>
              <li><a className="hover:text-gray-500" href="https://editsquare.com/terms">Terms Of Use</a></li>
              <li><a className="hover:text-gray-500" href="https://editsquare.com/privacy-policy">Privacy Policy</a></li>
            </ul>
          </div>
          <div>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a href="https://twitter.com/editsquare" className="text-gray-500">
                <FaTwitter />
              </a>
              <a href="https://instagram.com/editsquare" className="ml-3 text-gray-500">
                <FaInstagram />
              </a>
              <a href="https://youtube.com/channel/UCp9qP9N7aGhMswnT2w7mmRw" className="ml-3 text-gray-500">
                <FaYoutube />
              </a>
              <a href="mailto:hello@editsquare.com" className="ml-3 text-gray-500">
                <FiMail />
              </a>
            </span>
          </div>
        </div>
        <div className="container px-6 pb-5 mx-auto">
          <p className="text-sm text-gray-500 sm:py-2 sm:mt-0 mt-4">
            mockupclips.com is a product of Edit Square LTD © {currentYear} <br />
            <span className="text-xs">Company No. 09971898</span>
          </p>
        </div>
      </footer>
    </div>
   </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
